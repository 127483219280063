var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "img-wrap" },
    _vm._l(_vm.imgList, function (item, index) {
      return _c(
        "div",
        { key: index },
        [
          _c("el-image", {
            staticStyle: {
              width: "100px",
              height: "100px",
              "margin-right": "20px",
            },
            attrs: { src: item, "preview-src-list": _vm.imgList },
          }),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }