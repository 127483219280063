<template>
  <div class="img-wrap">
    <div v-for="(item, index) in imgList" :key="index">
      <el-image
        style="width: 100px; height: 100px; margin-right: 20px"
        :src="item"
        :preview-src-list="imgList"
      >
      </el-image>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: Array,
  },
  watch: {
    value() {
      // const temp = [];
      let photos = '';
      console.log(this.value);
      photos = this.value[0].photos;
      this.imgList = photos.split(',');
      // for (const item of this.value) {
      //   if (item.photos.indexOf(',') !== -1) {
      //     const photos = item.photos.split(',');
      //     temp.push(photos);
      //   } else {
      //     temp.push(item.photos);
      //   }
      // }
      // console.log(temp);
      // this.imgList = temp;
      // console.log(this.imgList);
    },
  },
  data() {
    return {
      imgList: [],
    };
  },

  methods: {},
};
</script>
<style scoped>
.img-wrap {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 20px;
}
</style>
