var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "edit-table" },
    [
      _c(
        "vxe-table",
        {
          ref: "fineTable",
          attrs: { data: _vm.tableData, "max-height": "500px" },
        },
        [
          _c("vxe-table-column", {
            attrs: {
              "min-width": "100",
              field: "productCode",
              title: "产品编码",
            },
          }),
          _c("vxe-table-column", {
            attrs: {
              "min-width": "100",
              field: "productName",
              title: "产品名称",
            },
          }),
          _c("vxe-table-column", {
            attrs: {
              "min-width": "120",
              field: "productType",
              title: "产品类型",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    row.productType === "GIVE"
                      ? _c("span", [_vm._v("赠送产品")])
                      : _vm._e(),
                    row.productType === "THROW"
                      ? _c("span", [_vm._v("投放产品")])
                      : _vm._e(),
                    row.productType === "ENTRY"
                      ? _c("span", [_vm._v("进场产品")])
                      : _vm._e(),
                    row.productType === "CONFER"
                      ? _c("span", [_vm._v("会议用酒")])
                      : _vm._e(),
                    row.productType === "PROCUREMENT"
                      ? _c("span", [_vm._v("采购商品")])
                      : _vm._e(),
                    row.productType === "BANQUET"
                      ? _c("span", [_vm._v("宴席用酒")])
                      : _vm._e(),
                    row.productType === "MEETING_WINE"
                      ? _c("span", [_vm._v("会议用酒")])
                      : _vm._e(),
                    row.productType === "GIVING_PRODUCT"
                      ? _c("span", [_vm._v("赠送产品")])
                      : _vm._e(),
                    row.productType === "GIFT"
                      ? _c("span", [_vm._v("礼品")])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _c("vxe-table-column", {
            attrs: {
              "min-width": "100",
              field: "productNum",
              title: "产品数量",
            },
          }),
          _c("vxe-table-column", {
            attrs: {
              "min-width": "100",
              field: "productPrice",
              title: "产品价格",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }